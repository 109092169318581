<template>
    <div class="software-rollback">
        <b-modal id="software-rollback-modal" @hide="onClose">
            <template #modal-title>
                <h3>Rollback Software</h3>
            </template>
            <template>
                <div>
                    <b-form-select v-model="selectedFirmware" :options="firmware" :value-field="'firmware_id'" :text-field="'firmware_description'" :select-size="4" :disabled="firmware.length === 0"></b-form-select>
                    <div v-if="selectedFirmware != null" class="mt-3">Selected Firmware: <strong>{{ firmware.find(f => f.firmware_id == selectedFirmware).firmware_description }}</strong></div>
                    <div v-if="nodata" class="mt-3">No available rollback targets</div>
                </div>
            </template>
            <template #modal-footer>
                <button @click="submitRollback">Confirm Rollback</button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import SoftwareService from '../../services/software.service';

export default {
    name: "SoftwareRollbackModal",
    props: {
        softwareId: Number,
    },
    components: {},
    data() {
        return {
            firmware: [],
            selectedFirmware: null,
            nodata: false
        }
    },
    emits: ['changeComplete'],
    watch: {
        softwareId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.grabFirmware(); 
            }
        }
    },
    methods: {
        grabFirmware(){
            this.firmware = [];
            this.selectedFirmware = null;
            this.nodata = false;
            SoftwareService.ListRollbackFirmware(this.softwareId).then(response => {
                if (response) {
                    if (response.data.firmware_list.length > 0){
                        this.firmware = response.data.firmware_list;
                        this.selectedFirmware = response.data.firmware_list[0].firmware_id;
                    }
                    else {
                        this.nodata = true;
                    }
                }
            })
        },
        submitRollback(){
            SoftwareService.SubmitRollbackFirmware(this.softwareId, this.selectedFirmware).then(response => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? "Success" : "Error",
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true
                    })
                    
                    this.$emit('changeComplete');
                }
            })
        },
        onClose(){
            this.firmware = [];
            this.selectedFirmware = null;
            this.nodata = false;
        }
    }
}

</script>